import { createCrudActions } from '../utils/crudGenerator';

const formatDevices = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchDevices,
    create: createDevice,
    update: updateDevice,
    delete: deleteDevices
} = createCrudActions('DEVICE', "Dispositivos", formatDevices);