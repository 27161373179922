//import { ROLES } from "./permissions";

export const routes = {
	home: {
		key: "home",
		slug: "home",
		to: "/",
		title: "Dashboard",
		icon: <i className="fas fa-chart-pie"></i>,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		// role: [ROLES.admin.role],
	},

	profile: {
		key: "profile",
		slug: "profile",
		to: "/profile",
		title: "Perfil",
		icon: <i className="fad fa-user-circle fa-swap-opacity"></i>,
		showInMenu: false,
		showHeader: true,
		showSearch: true,
		action: null,
		// role: [ROLES.admin.role],
	},

	users: {
		key: "users",
		slug: "users",
		to: "/users",
		title: "Usuarios",
		icon: <i className="fad fa-poll-people fa-swap-opacity"></i>,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		// role: [ROLES.admin.role],
	},

	login: {
		key: "login",
		slug: "login",
		to: "/login",
		title: "LOGIN",
		icon: null,
		showInMenu: false,
		showHeader: false,
		showSearch: false,
		action: null,
		// role: [ROLES.admin.role],
	},

	dosistats: {
		key: "dosistats",
		slug: "dosistats",
		to: "/dosistats",
		title: "Estadisticas Dosificadors",
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		//role: [ROLES.admin.role],
	},

	procediments: {
		key: "procediments",
		slug: "procediments",
		to: "/procediments",
		title: "Procedimientos",
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		// role: [ROLES.admin.role],
	},
	purchasess: {
		key: "purchasess",
		slug: "purchasess",
		to: "/purchasess",
		title: "Compras a proveedores",
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		parent: "buying",
		// role: [ROLES.admin.role],
	},

	/* devices: {
    key: "devices",
    slug: "devices",
    to: "/devices",
    title: "Dispositivos",
    icon: <i className="fad fa-poll-people fa-swap-opacity"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    //role: [ROLES.admin.role],
  }, */

	/* pcs: {
    key: "pcs",
    slug: "pcs",
    to: "/pcs",
    title: "Pcs",
    icon: <i className="fad fa-poll-people fa-swap-opacity"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    //role: [ROLES.admin.role],
  }, */
	logout: {
		key: "logout",
		slug: "logout",
		to: "/logout",
		title: "Cerrar Sesión",
		icon: <i className="fad fa-sign-out"></i>,
		showInMenu: true,
		showHeader: false,
		showSearch: false,
		action: null,
		// role: [ROLES.admin.role],
	},
};
