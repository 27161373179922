import { combineReducers } from "redux";
import {
	authorization,
	profile,
	currentRoute,
	notifications,
	app,
	usersForm,
	users,
	equipmentConfigurations,
	equipmentConfigurationsForm,
	instalations,
	instalationsForm,
	projects,
	projectsForm,
	imputHours,
	imputHoursForm,
	singUps,
	singUpsForm,
	equipments,
	equipmentsForm,
	navUsers,
	shipToAddresses,
	incidences,
	incidencesForm,
	satisfactions,
	satisfactionsForm,
	alerts,
	alertsForm,
	shopCarts,
	shopCartsForm,
	articles,
	securitySheets,
	securitySheetsForm,
	invoices,
	deliveryNotes,
	downloadDocuments,
	signForm,
	repartidors,
	repartiments,
	suggestions,
	suggestionsForm,
	establishmentInfo,
	establishmentInfoForm,
	tags,
	tagsForm,
	expenses,
	expensesForm,
	OPCUA,
	OPCUAForm,
	timeRequests,
	timeRequestsForm,
	holidayRequests,
	holidayRequestsForm,
	workShifts,
	workShiftsForm,
	dosificationProducts,
	dosificationProductsForm,
	dosificationConfigs,
	dosificationConfigsForm,
	leaves,
	leavesForm,
	reasons,
	reasonsForm,
	workOrders,
	workOrdersForm,
	products,
	rolesForm,
	roles,
	dosifications,
	dosificationsForm,
	raws,
	rawsForm,
	episForm,
	epis,
	allInstalations,
	allEquipments,
	currentTheme,
	stocks,
	stocksForm,
	dosistats,
	dosistatsForm,
	projectsnav,
	repcon,
	tasks,
	tasksForm,
	permissions,
	permissionsForm,
	events,
	eventsForm,
	procediments,
	procedimentsForm,
	oficialDocuments,
	oficialDocumentsForm,
	purchasess,
	purchasessForm,
	providers,
	devices,
	devicesForm,
	pcs,
	pcsForm,
	sales,
	salesForm,
} from "./Reducers";

export default combineReducers({
	sales,
	salesForm,
	pcs,
	pcsForm,
	devices,
	devicesForm,
	purchasess,
	purchasessForm,
	oficialDocuments,
	oficialDocumentsForm,
	procediments,
	procedimentsForm,
	events,
	eventsForm,
	permissions,
	permissionsForm,
	tasks,
	tasksForm,
	dosistats,
	dosistatsForm,
	stocks,
	stocksForm,
	app,
	authorization,
	currentRoute,
	profile,
	notifications,
	usersForm,
	users,
	equipmentConfigurations,
	equipmentConfigurationsForm,
	instalations,
	instalationsForm,
	projects,
	projectsForm,
	imputHours,
	imputHoursForm,
	singUps,
	singUpsForm,
	equipments,
	equipmentsForm,
	navUsers,
	shipToAddresses,
	incidences,
	incidencesForm,
	satisfactions,
	satisfactionsForm,
	alerts,
	alertsForm,
	shopCarts,
	shopCartsForm,
	articles,
	securitySheets,
	securitySheetsForm,
	invoices,
	deliveryNotes,
	downloadDocuments,
	signForm,
	repartidors,
	repartiments,
	suggestions,
	suggestionsForm,
	establishmentInfo,
	establishmentInfoForm,
	tags,
	tagsForm,
	expenses,
	expensesForm,
	OPCUA,
	OPCUAForm,
	timeRequests,
	timeRequestsForm,
	holidayRequests,
	holidayRequestsForm,
	workShifts,
	workShiftsForm,
	dosificationProducts,
	dosificationProductsForm,
	dosificationConfigs,
	dosificationConfigsForm,
	leaves,
	leavesForm,
	reasons,
	reasonsForm,
	workOrders,
	workOrdersForm,
	products,
	rolesForm,
	roles,
	dosifications,
	dosificationsForm,
	raws,
	rawsForm,
	episForm,
	epis,
	allInstalations,
	allEquipments,
	currentTheme,
	projectsnav,
	repcon,
	providers,
});
