import { createCrudActions } from "../utils/crudGenerator";

const formatSales = (data) => {
	if (!data) {
		return [];
	}
	const formatItem = (item) => ({
		...item,
		label: `${item.name}`,
		value: item._id,
	});

	if (Array.isArray(data)) {
		return data.map(formatItem);
	}
	return formatItem(data);
};

export const {
	fetch: fetchSales,
	create: createSale,
	update: updateSale,
	delete: deleteSales,
} = createCrudActions("SALE", "Ventas Desimper", formatSales);
