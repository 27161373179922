// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	oficialDocuments: {
		create: `/oficialDocument`,
		edit: `/oficialDocument/`,
		delete: `/oficialDocument/`,
		list: `/oficialDocument`,
	},
	procediments: {
		create: `/procediment`,
		edit: `/procediment/`,
		delete: `/procediment/`,
		list: `/procediment`,
	},
	events: {
		create: `/event`,
		edit: `/event/`,
		delete: `/event/`,
		list: `/event`,
	},
	permissions: {
		create: `/permission`,
		edit: `/permission/`,
		delete: `/permission/`,
		list: `/permission`,
	},
	tasks: {
		create: `/task`,
		edit: `/task/`,
		delete: `/task/`,
		list: `/task`,
		archive: `/archiveTasks`,
	},
	dosistats: {
		create: `/dosistat`,
		edit: `/dosistat/`,
		delete: `/dosistat/`,
		list: `/dosistat`,
	},
	stocks: {
		create: `/stock`,
		edit: `/stock/`,
		delete: `/stock/`,
		list: `/stock`,
	},
	auth: {
		login: `/user/login`,
		check2FA: `/user/check2FA`,
		forgotPassword: `/user/forgotPassword`,
	},
	users: {
		profile: `/user/profile`,
		create: `/user/register/`,
		edit: `/user/`,
		recover: `/recover/`,
		delete: `/user/`,
		list: `/user`,
		navUsers: `/navUsers`,
		getShipToAddresses: `/getShipToAddresses`,
		providers: `/providers`,
		products: `/products`,
		productsPF: `/productsPF`,
		repcon: `/repcon`,
		projectsNav: `/projectsNav`,
	},
	equipmentConfigurations: {
		create: `/equipmentConfiguration`,
		edit: `/equipmentConfiguration/`,
		delete: `/equipmentConfiguration/`,
		list: `/equipmentConfiguration`,
	},
	instalations: {
		create: `/instalation`,
		edit: `/instalation/`,
		delete: `/instalation/`,
		list: `/instalation`,
		all: `/instalationAll`,
	},
	securitySheets: {
		create: `/securitySheet`,
		edit: `/securitySheet/`,
		delete: `/securitySheet/`,
		list: `/securitySheet`,
	},
	shopCartLines: {
		create: `/shopCartLine`,
		edit: `/shopCartLine/`,
		delete: `/shopCartLine/`,
		list: `/shopCartLine`,
	},
	satisfactions: {
		create: `/satisfaction`,
		edit: `/satisfaction/`,
		delete: `/satisfaction/`,
		list: `/satisfaction`,
	},
	purchasess: {
		create: `/purchases`,
		edit: `/purchases/`,
		delete: `/purchases/`,
		list: `/purchases`,
		notifyDeliveryNote: `/purchases/notify/`,
	},
	incidences: {
		create: `/incidence`,
		edit: `/incidence/`,
		delete: `/incidence/`,
		list: `/incidence`,
	},
	shopCarts: {
		create: `/shopCart`,
		edit: `/shopCart/`,
		delete: `/shopCart/`,
		list: `/shopCart`,
		articles: `/articles`,
		deliveryNotes: `/deliveryNotes/`,
		invoices: `/invoices/`,
		security: `/security/`,
		invoicePDF: `/invoicePDF/`,
		deliveryNotePDF: `/deliveryNotePDF/`,
		deliverySign: `/deliverySign`,
		invoiceSign: `/invoiceSign`,
		repartidors: `/repartidors`,
		deliveryRepartiment: `/deliveryRepartiment`,
	},
	equipments: {
		create: `/equipment`,
		edit: `/equipment/`,
		delete: `/equipment/`,
		list: `/equipment`,
		all: `/equipmentAll`,
	},
	singUps: {
		create: `/singUp`,
		edit: `/singUp/`,
		delete: `/singUp/`,
		list: `/singUp`,
		all: `/allSingUp`,
	},
	projects: {
		create: `/project`,
		edit: `/project/`,
		delete: `/project/`,
		list: `/project`,
	},
	suggestions: {
		create: `/suggestion`,
		edit: `/suggestion/`,
		delete: `/suggestion/`,
		list: `/suggestion`,
	},
	workOrders: {
		create: `/workOrder`,
		edit: `/workOrder/`,
		delete: `/workOrder/`,
		list: `/workOrder`,
		order: "/order",
	},
	tags: {
		create: `/tag`,
		edit: `/tag/`,
		delete: `/tag/`,
		list: `/tag`,
	},
	dosifications: {
		create: `/dosification`,
		edit: `/dosification/`,
		delete: `/dosification/`,
		list: `/dosification`,
	},
	reasons: {
		create: `/reason`,
		edit: `/reason/`,
		delete: `/reason/`,
		list: `/reason`,
	},
	epis: {
		create: `/epi`,
		edit: `/epi/`,
		delete: `/epi/`,
		list: `/epi`,
	},
	raws: {
		create: `/raw`,
		edit: `/raw/`,
		delete: `/raw/`,
		list: `/raw`,
	},
	roles: {
		create: `/role`,
		edit: `/role/`,
		delete: `/role/`,
		list: `/role`,
	},
	workShift: {
		create: `/workShift`,
		edit: `/workShift/`,
		delete: `/workShift/`,
		list: `/workShift`,
	},
	leave: {
		create: `/leave`,
		edit: `/leave/`,
		delete: `/leave/`,
		list: `/leave`,
	},
	dosificationProduct: {
		create: `/dosificationProduct`,
		edit: `/dosificationProduct/`,
		delete: `/dosificationProduct/`,
		list: `/dosificationProduct`,
	},
	dosificationconfigs: {
		create: `/dosificatorConfig`,
		edit: `/dosificatorConfig/`,
		delete: `/dosificatorConfig/`,
		list: `/dosificatorConfig`,
	},
	OPCUA: {
		create: `/OPCUA`,
		edit: `/OPCUA/`,
		delete: `/OPCUA/`,
		list: `/OPCUA`,
		browse: `/OPCUA/browse`,
	},
	expenses: {
		create: `/expense`,
		edit: `/expense/`,
		delete: `/expense/`,
		list: `/expense`,
	},
	establishmentInfo: {
		create: `/establishmentInfo`,
		edit: `/establishmentInfo/`,
		delete: `/establishmentInfo/`,
		list: `/establishmentInfo`,
	},
	imputHours: {
		create: `/imputHour`,
		edit: `/imputHour/`,
		delete: `/imputHour/`,
		list: `/imputHour`,
	},
	timeRequests: {
		create: `/timeRequest`,
		edit: `/timeRequest/`,
		delete: `/timeRequest/`,
		list: `/timeRequest`,
	},
	holidayRequests: {
		create: `/holidayRequest`,
		edit: `/holidayRequest/`,
		delete: `/holidayRequest/`,
		list: `/holidayRequest`,
	},
	alerts: {
		create: `/alert`,
		edit: `/alert/`,
		delete: `/alert/`,
		list: `/alert`,
	},
	downloadDocuments: {
		list: `/downloadDocument`,
	},
};
