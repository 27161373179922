import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
	ADD_NEW_SHOPCART,
	DELETE_SHOPCART,
	SHOPCART_FORM_TOOGLE_LOADING,
	SET_SHOPCART_LIST,
	SHOW_NOTIFICATION,
	SET_ARTICLES_LIST,
	SET_INVOICES_LIST,
	SET_DELIVERYNOTES_LIST,
	SET_REPARTIDORS_LIST,
	SET_REPARTIMENTS_LIST,
} from "../constants";
import {
	formatArticles,
	formatInvoices,
	formatRepartidors,
	formatRepartiments,
	formatShopCarts,
} from "./settingsActionsUtils";

/* SHOPCART LIST */
export const fetchShopCarts = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.list)
			.then((response) => {
				const shopCarts = formatShopCarts(response.data);
				dispatch({
					type: SET_SHOPCART_LIST,
					payload: keyBy(shopCarts, "_id"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchArticles = (business) => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.articles + (business ? "?business=" + business : ""))
			.then((response) => {
				const shopCarts = formatArticles(response.data);
				dispatch({
					type: SET_ARTICLES_LIST,
					payload: keyBy(shopCarts, "value"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchRepartidors = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.repartidors)
			.then((response) => {
				const data = formatRepartidors(response.data);
				dispatch({
					type: SET_REPARTIDORS_LIST,
					payload: keyBy(data, "value"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchRepartiments = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.deliveryRepartiment)
			.then((response) => {
				const shopCarts = formatRepartiments(response.data);
				dispatch({
					type: SET_REPARTIMENTS_LIST,
					payload: keyBy(shopCarts, "value"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchInvoices = (business) => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.invoices + business)
			.then((response) => {
				const shopCarts = formatInvoices(response.data);
				dispatch({
					type: SET_INVOICES_LIST,
					payload: keyBy(shopCarts, "value"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchDeliveryNotes = (business) => {
	return async (dispatch) => {
		const response = await axios
			.get(API.shopCarts.deliveryNotes + business)
			.then((response) => {
				const shopCarts = formatInvoices(response.data);
				dispatch({
					type: SET_DELIVERYNOTES_LIST,
					payload: keyBy(shopCarts, "value"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchSecurityPDF = (original, no) => {
	return async () => {
		const response = await axios
			.get(API.shopCarts.security + original + "/" + no)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};
export const fetchInvoicePDF = (no) => {
	return async () => {
		const response = await axios
			.get(API.shopCarts.invoicePDF + no)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const fetchDeliveryNotePDF = (no) => {
	return async () => {
		const response = await axios
			.get(API.shopCarts.deliveryNotePDF + no)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

// SHOPCARTS
export const createShopCart = (newShopCart) => {
	return async (dispatch) => {
		dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(API.shopCarts.create, newShopCart)
			.then((response) => {
				const { _id } = response.data;
				const shopCart = formatShopCarts({ ...newShopCart, _id });
				dispatch({ type: ADD_NEW_SHOPCART, payload: shopCart });
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "success",
						message: "Carrito creado con éxito.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const signDelivery = (post) => {
	return async (dispatch) => {
		const response = await axios
			.post(API.shopCarts.deliverySign, post)
			.then((response) => {
				return response?.status;
			})
			.catch((err) => {
				return err;
			});
		return response.status;
	};
};

export const signInvoice = (post) => {
	return async (dispatch) => {
		const response = await axios
			.post(API.shopCarts.invoiceSign, post)
			.then((response) => {
				return response?.status;
			})
			.catch((err) => {
				return err;
			});
		return response;
	};
};

export const updateShopCart = (updatedShopCart) => {
	return async (dispatch) => {
		dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.shopCarts.edit}${updatedShopCart?._id}`, updatedShopCart)
			.then((response) => {
				const shopCarts = formatShopCarts(response.data);
				dispatch({
					type: SET_SHOPCART_LIST,
					payload: keyBy(shopCarts, "_id"),
				});
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Carrito actualizado.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteShopCarts = (Ids) => {
	return async (dispatch) => {
		dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
		const response = await axios
			.delete(`${API.shopCarts.delete}`, { data: Ids })
			.then((response) => {
				dispatch({ type: DELETE_SHOPCART, payload: Ids });
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Carrito eliminado.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: SHOPCART_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};
