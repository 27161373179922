import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
	(state) => state.authorization,
	(authorization) => authorization
);
export const getCurrentRoute = createSelector(
	(state) => state.currentRoute,
	(currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
	getCurrentRoute,
	(route) => route.title
);
export const getSideMenuState = createSelector(
	(state) => state.app,
	(app) => app.isCollapsedSideMenu
);

/* PROFILE */
export const getUserProfile = createSelector(
	(state) => state.profile,
	(profile) => profile
);
export const currentUserId = createSelector(
	(state) => state.profile,
	(profile) => profile && profile._id
);
export const getCurrentUserRole = createSelector(
	getUserProfile,
	(profile) => profile?.role
);
export const getCurrentTheme = createSelector(
	(state) => state.currentTheme,
	(currentTheme) => currentTheme
);
/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
	(state) => state.notifications,
	(notifications) => notifications
);

/* USERS */
export const getNavUsers = createSelector(
	(state) => state.navUsers,
	(navUsers) => navUsers
);
export const getShipToAddresses = createSelector(
	(state) => state.shipToAddresses,
	(shipToAddresses) => shipToAddresses
);
export const getProviders = createSelector(
	(state) => state.providers,
	(providers) => providers
);
export const getUsers = createSelector(
	(state) => state.users,
	(users) => users
);
export const getRepcon = createSelector(
	(state) => state.repcon,
	(repcon) => repcon
);
export const getProjectsNav = createSelector(
	(state) => state.projectsnav,
	(projectsnav) => projectsnav
);
export const getProducts = createSelector(
	(state) => state.products,
	(products) => products
);
export const getUsersForm = createSelector(
	(state) => state.usersForm,
	(form) => form
);

/* EQUIPMENTCONFIGURATIONS */
export const getEquipmentConfigurations = createSelector(
	(state) => state.equipmentConfigurations,
	(equipmentConfigurations) => equipmentConfigurations
);
export const getEquipmentConfigurationsForm = createSelector(
	(state) => state.equipmentConfigurationsForm,
	(form) => form
);

/* DOWNLOADDOCUMENT */
export const getDownloadDocuments = createSelector(
	(state) => state.downloadDocuments,
	(downloadDocuments) => downloadDocuments
);

/* INSTALATIONS */
export const getInstalations = createSelector(
	(state) => state.instalations,
	(instalations) => instalations
);
export const getInstalationsForm = createSelector(
	(state) => state.instalationsForm,
	(form) => form
);

/* SECURITYSHEETS */
export const getSecuritySheets = createSelector(
	(state) => state.securitySheets,
	(securitySheets) => securitySheets
);
export const getSecuritySheetsForm = createSelector(
	(state) => state.securitySheetsForm,
	(form) => form
);

/* SHOPCART */
export const getShopCarts = createSelector(
	(state) => state.shopCarts,
	(shopCarts) => shopCarts
);
export const getArticles = createSelector(
	(state) => state.articles,
	(articles) => articles
);
export const getInvoices = createSelector(
	(state) => state.invoices,
	(invoices) => invoices
);
export const getDeliveryNotes = createSelector(
	(state) => state.deliveryNotes,
	(deliveryNotes) => deliveryNotes
);
export const getShopCartsForm = createSelector(
	(state) => state.shopCartsForm,
	(form) => form
);

/* SATISFACTIONS */
export const getSatisfactions = createSelector(
	(state) => state.satisfactions,
	(satisfactions) => satisfactions
);
export const getSatisfactionsForm = createSelector(
	(state) => state.satisfactionsForm,
	(form) => form
);

/* INCIDENCES */
export const getIncidences = createSelector(
	(state) => state.incidences,
	(incidences) => incidences
);
export const getIncidencesForm = createSelector(
	(state) => state.incidencesForm,
	(form) => form
);

/* EQUIPMENTS */
export const getEquipments = createSelector(
	(state) => state.equipments,
	(equipments) => equipments
);
export const getEquipmentsForm = createSelector(
	(state) => state.equipmentsForm,
	(form) => form
);

/* SINGUPS */
export const getSingUps = createSelector(
	(state) => state.singUps,
	(singUps) => singUps
);
export const getSingUpsForm = createSelector(
	(state) => state.singUpsForm,
	(form) => form
);

/* PROJECTS */
export const getProjects = createSelector(
	(state) => state.projectsnav,
	(projects) => projects
);
export const getProjectsForm = createSelector(
	(state) => state.projectsForm,
	(form) => form
);

/* SUGGESTIONS */
export const getSuggestions = createSelector(
	(state) => state.suggestions,
	(suggestions) => suggestions
);
export const getSuggestionsForm = createSelector(
	(state) => state.suggestionsForm,
	(form) => form
);

/* WORKORDERS */
export const getWorkOrders = createSelector(
	(state) => state.workOrders,
	(workOrders) => workOrders
);
export const getWorkOrdersForm = createSelector(
	(state) => state.workOrdersForm,
	(form) => form
);

/* TAGS */
export const getTags = createSelector(
	(state) => state.tags,
	(tags) => tags
);
export const getTagsForm = createSelector(
	(state) => state.tagsForm,
	(form) => form
);

/* DOSIFICATIONS */
export const getDosifications = createSelector(
	(state) => state.dosifications,
	(dosifications) => dosifications
);
export const getDosificationsForm = createSelector(
	(state) => state.dosificationsForm,
	(form) => form
);

/* REASONS */
export const getReasons = createSelector(
	(state) => state.reasons,
	(reasons) => reasons
);
export const getReasonsForm = createSelector(
	(state) => state.reasonsForm,
	(form) => form
);

/* EPIS */
export const getEpis = createSelector(
	(state) => state.epis,
	(epis) => epis
);
export const getEpisForm = createSelector(
	(state) => state.episForm,
	(form) => form
);

/* RAWS */
export const getRaws = createSelector(
	(state) => state.raws,
	(raws) => raws
);
export const getRawsForm = createSelector(
	(state) => state.rawsForm,
	(form) => form
);

/* ROLES */
export const getRoles = createSelector(
	(state) => state.roles,
	(roles) => roles
);
export const getRolesForm = createSelector(
	(state) => state.rolesForm,
	(form) => form
);

/* WORKSHIFTS */
export const getWorkShifts = createSelector(
	(state) => state.workShifts,
	(workShifts) => workShifts
);
export const getWorkShiftsForm = createSelector(
	(state) => state.workShiftsForm,
	(form) => form
);

/* LEAVES */
export const getLeaves = createSelector(
	(state) => state.leaves,
	(leaves) => leaves
);
export const getLeavesForm = createSelector(
	(state) => state.leavesForm,
	(form) => form
);

/* DOSIFICATIONPRODUCT */
export const getDosificationProducts = createSelector(
	(state) => state.dosificationProducts,
	(dosificationProducts) => dosificationProducts
);
export const getDosificationProductsForm = createSelector(
	(state) => state.dosificationProductsForm,
	(form) => form
);

/* DOSIFICATIONCONFIG */
export const getDosificationConfigs = createSelector(
	(state) => state.dosificationConfigs,
	(dosificationConfigs) => dosificationConfigs
);
export const getDosificationConfigsForm = createSelector(
	(state) => state.dosificationConfigsForm,
	(form) => form
);

/* OPCUA */
export const getOPCUA = createSelector(
	(state) => state.OPCUA,
	(OPCUA) => OPCUA
);
export const getOPCUAForm = createSelector(
	(state) => state.OPCUAForm,
	(form) => form
);

/* EXPENSES */
export const getExpenses = createSelector(
	(state) => state.expenses,
	(expenses) => expenses
);
export const getExpensesForm = createSelector(
	(state) => state.expensesForm,
	(form) => form
);

/* ESTABLISHMENTINFOS */
export const getEstablishmentInfo = createSelector(
	(state) => state.establishmentInfo,
	(establishmentInfo) => establishmentInfo
);
export const getEstablishmentInfoForm = createSelector(
	(state) => state.establishmentInfoForm,
	(form) => form
);

/* IMPUTHOURS */
export const getImputHours = createSelector(
	(state) => state.imputHours,
	(imputHours) => imputHours
);
export const getImputHoursForm = createSelector(
	(state) => state.imputHoursForm,
	(form) => form
);

/* TIMEREQUESTS */
export const getTimeRequests = createSelector(
	(state) => state.timeRequests,
	(timeRequests) => timeRequests
);
export const getTimeRequestsForm = createSelector(
	(state) => state.timeRequestsForm,
	(form) => form
);

/* HOLIDAYREQUESTS */
export const getHolidayRequests = createSelector(
	(state) => state.holidayRequests,
	(holidayRequests) => holidayRequests
);
export const getHolidayRequestsForm = createSelector(
	(state) => state.holidayRequestsForm,
	(form) => form
);

/* ALERT */
export const getAlerts = createSelector(
	(state) => state.alerts,
	(alerts) => alerts
);
export const getAlertsForm = createSelector(
	(state) => state.alertsForm,
	(form) => form
);

/* SIGN */
export const getSignForm = createSelector(
	(state) => state.signForm,
	(form) => form
);

/* REPARTIDORS */
export const getRepartidors = createSelector(
	(state) => state.repartidors,
	(repartidors) => repartidors
);

/* REPARTIMENTS */
export const getRepartiments = createSelector(
	(state) => state.repartiments,
	(repartiments) => repartiments
);

export const getAllInstalations = createSelector(
	(state) => state.allInstalations,
	(allInstalations) => allInstalations
);

export const getAllEquipments = createSelector(
	(state) => state.allEquipments,
	(allEquipments) => allEquipments
);

/* STATE */
export const getStateReact = (sta) =>
	createSelector(
		(state) => state[sta],
		(data) => data
	);

/* STOCKS */
export const getStocks = createSelector(
	(state) => state.stocks,
	(stocks) => stocks
);
export const getStocksForm = createSelector(
	(state) => state.stocksForm,
	(form) => form
);

/* DOSISTATS */
export const getDosistats = createSelector(
	(state) => state.dosistats,
	(dosistats) => dosistats
);
export const getDosistatsForm = createSelector(
	(state) => state.dosistatsForm,
	(form) => form
);

/* TASKS */
export const getTasks = createSelector(
	(state) => state.tasks,
	(tasks) => tasks
);
export const getTasksForm = createSelector(
	(state) => state.tasksForm,
	(form) => form
);

/* PERMISSIONS */
export const getPermissions = createSelector(
	(state) => state.permissions,
	(permissions) => permissions
);
export const getPermissionsForm = createSelector(
	(state) => state.permissionsForm,
	(form) => form
);

/* EVENTS */
export const getEvents = createSelector(
	(state) => state.events,
	(events) => events
);
export const getEventsForm = createSelector(
	(state) => state.eventsForm,
	(form) => form
);

/* PROCEDIMENTS */
export const getProcediments = createSelector(
	(state) => state.procediments,
	(procediments) => procediments
);
export const getProcedimentsForm = createSelector(
	(state) => state.procedimentsForm,
	(form) => form
);

/* OFICIALDOCUMENTS */
export const getOficialDocuments = createSelector(
	(state) => state.oficialDocuments,
	(oficialDocuments) => oficialDocuments
);
export const getOficialDocumentsForm = createSelector(
	(state) => state.oficialDocumentsForm,
	(form) => form
);

/* PURCHASESS */
export const getPurchasess = createSelector(
	(state) => state.purchasess,
	(purchasess) => purchasess
);
export const getPurchasessForm = createSelector(
	(state) => state.purchasessForm,
	(form) => form
);

/* DEVICES */
export const getDevices = createSelector(
	(state) => state.devices,
	(devices) => devices
);
export const getDevicesForm = createSelector(
	(state) => state.devicesForm,
	(form) => form
);

/* PCS */
export const getPcs = createSelector(
	(state) => state.pcs,
	(pcs) => pcs
);
export const getPcsForm = createSelector(
	(state) => state.pcsForm,
	(form) => form
);

/* SALES */
export const getSales = createSelector(
	(state) => state.sales,
	(sales) => sales
);
export const getSalesForm = createSelector(
	(state) => state.salesForm,
	(form) => form
);
