import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatOficialDocuments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatProcediments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatEvents = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatPermissions = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatTasks = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatDosistats = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};
export const formatStocks = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};
export const formatUsers = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name} ${data.surname}`,
			value: data._id,
			last_login: data.last_login && moment(data.last_login),
			holidaysPerYear: data.holidaysPerYear
				? data.holidaysPerYear.map((holiday) => ({
						year: moment(holiday.year),
						days: holiday.days,
				  }))
				: [],
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name} ${data.surname}`,
			last_login: data.last_login && moment(data.last_login),
			holidaysPerYear: data.holidaysPerYear
				? data.holidaysPerYear.map((holiday) => ({
						year: moment(holiday.year),
						days: holiday.days,
				  }))
				: [],
		};
	}
};

export const formatNavUsers = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Name}`,
			value: data.No,
		};
	}
};

export const formatShipToAddresses = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Customer_No} - ${data.Name}, ${data.Address}, ${data.City} ${data.Post_Code}`,
			value: `${data.Customer_No} - ${data.Name}, ${data.Address}, ${data.City} ${data.Post_Code}`,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Customer_No} - ${data.Name}, ${data.Address}, ${data.City} ${data.Post_Code}`,
			value: `${data.Customer_No} - ${data.Name}, ${data.Address}, ${data.City} ${data.Post_Code}`,
		};
	}
};

export const formatProviders = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Name}`,
			value: data.No,
		};
	}
};

export const formatProducts = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Description} - ${data.No}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Description} - ${data.No}`,
			value: data.No,
		};
	}
};

export const formatRepcon = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.Code,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Name}`,
			value: data.Code,
		};
	}
};

export const formatProjectsNav = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Description}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Description}`,
			value: data.No,
		};
	}
};

export const formatDosificationProduct = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.name}`,
			value: data._id,
		};
	}
};

export const formatDosificationConfigs = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data._id}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data._id}`,
			value: data._id,
		};
	}
};

export const formatEstablishmentInfo = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.Name}`,
			value: data.No,
		};
	}
};

export const formatEquipmentConfigurations = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};

export const formatInstalations = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};

export const formatSecuritySheets = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};

export const formatShopCarts = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatArticles = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data.Item_No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data.Item_No,
			label: `${data.name}`,
		};
	}
};

export const formatRepartidors = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.No,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data.No,
			label: `${data.Name}`,
		};
	}
};

export const formatRepartiments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.Name}`,
			value: data.No,
			Document_Date: data.Document_Date && moment(data.Document_Date),
			Fecha_Reparto: data.Fecha_Reparto && moment(data.Fecha_Reparto),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data.No,
			label: `${data.Name}`,
			Document_Date: data.Document_Date && moment(data.Document_Date),
			Fecha_Reparto: data.Fecha_Reparto && moment(data.Fecha_Reparto),
		};
	}
};

export const formatInvoices = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		let noFilters = data.map((data) => ({
			...data,
			label: `${data.Bill_to_Name}`,
			value: data.No,
			Document_Date: data.Document_Date && moment(data.Document_Date),
		}));
		noFilters = noFilters.filter(
			(x) => x.Document_Date >= moment().add(-1, "y")
		);
		return noFilters;
	}
	if (isObject(data)) {
		return {
			...data,
			value: data.No,
			label: `${data.Bill_to_Name}`,
			Document_Date: data.Document_Date && moment(data.Document_Date),
		};
	}
};

export const formatSatisfactions = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatEquipments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			lastCommunicate: data.lastCommunicate && moment(data.lastCommunicate),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			lastCommunicate: data.lastCommunicate && moment(data.lastCommunicate),
		};
	}
};

export const formatIncidences = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			start: data.start && moment(data.start),
			predictedend: data.predictedend && moment(data.predictedend),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.description}`,
			value: data._id,
			start: data.start && moment(data.start),
			predictedend: data.predictedend && moment(data.predictedend),
			end: data.end && moment(data.end),
		};
	}
};

export const formatProjects = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
		};
	}
};

export const formatImputHours = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatTimeRequests = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatHolidayRequests = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatSingUps = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatSuggestions = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatWorkOrders = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.no}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.no}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatTags = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};

export const formatDosifications = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatReasons = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};

export const formatEpis = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatRaws = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			date: data.date && moment(data.date),
		};
	}
};

export const formatRoles = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};

export const formatWorkShift = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatLeave = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
			end: data.end && moment(data.end),
			start: data.start && moment(data.start),
		};
	}
};

export const formatOPCUA = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.description}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.description}`,
		};
	}
};

export const formatExpenses = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: moment(data.date || data.createdAt)
				.set("s", 0)
				.set("ms", 0),
			end: data.end && moment(data.end).set("s", 0).set("ms", 0),
			dateFormat: moment(data.date || data.createdAt).format("LL"),
			hours: data.hours.map((hour) => ({
				start: moment(hour.start).set("s", 0).set("ms", 0),
				end: moment(hour.end).set("s", 0).set("ms", 0),
			})),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			label: `${data.name}`,
			value: data._id,
			date:
				data.date &&
				moment(data.date || data.createdAt)
					.set("s", 0)
					.set("ms", 0),
			end: data.end && moment(data.end).set("s", 0).set("ms", 0),
			dateFormat: moment(data.date || data.createdAt).format("LL"),
			hours: data.hours.map((hour) => ({
				start: moment(hour.start).set("s", 0).set("ms", 0),
				end: moment(hour.end).set("s", 0).set("ms", 0),
			})),
		};
	}
};

export const formatAlerts = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			label: `${data.title}`,
			value: data._id,
			...data,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.title}`,
		};
	}
};

export const formatDownloadDocuments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			label: `${data.title}`,
			value: data._id,
			...data,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.title}`,
		};
	}
};
